export const environment = {
  production: true,
  restTarget: 'https://contractlogixweb-prod.azurewebsites.net',
  adalConfig: {
    tenant: 'a5c460b6-6f06-464c-a3ce-003d212b0155',
    clientId: '29a86711-b108-4489-813b-ea8aa2c00906', // ContractLogix.TenantPortal web application in Azure
    endpoints: {
      'https://contractlogixweb-prod.azurewebsites.net/': '29a86711-b108-4489-813b-ea8aa2c00906',
    }
  },
  apiUrl: 'https://contractlogixweb-prod.azurewebsites.net',
  appInsightsConfig: {
    host: 'api.applicationinsights.io',
    appId: 'bc914f56-f39d-423f-8462-7b7e809108f7',
    apiKey: '67ht3un23z8oxt276iyacvc2gln0qdzhvtmo5gzr'
  }
};
